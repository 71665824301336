<template>
	<div>
		<div class="page--service container container-l mx-auto">
			<section>
				<hero :title="service.title" :text="service.discription">
					<img class="mx-auto" :src="service.main_media" alt="" />
				</hero>
			</section>
			<section v-if="service.tecnologies.length" class="tecnology-section">
				<h2 class="title text-2xl sm:text-4xl xl:text-6xl">
					{{ $t("headings.usedTechnologies") }}
				</h2>
				<div>
					<tecnologies :tecnologies="service.tecnologies" />
				</div>
			</section>
			<!-- <div class="flex justify-center">
				<button class="btn is-main nav-btn">
					<router-link to="/contact-us">
						Get Quotation Now
					</router-link>
				</button>
			</div> -->
			<section v-if="service.sections.length" class="packages-section">
				<div class="container mx-auto">
					<ul class="packages-header">
						<li
							v-for="section in service.sections"
							:key="section.id"
							class="section-details"
							:id="`section-${section.id}`"
						>
							<button @click="changeSection(section)">
								{{ section.name }}
							</button>
						</li>
					</ul>
					<div v-if="selectedSection" class="packages-content">
						<p class="package-description">
							<span>
								{{ selectedSection.discription }}
							</span>
						</p>
						<div
							class="package-types"
							v-if="selectedSection.types.length === 4"
						>
							<div
								v-for="pack in selectedSection.types"
								:key="pack.id"
								class="package-type-container"
							>
								<div class="package-type">
									<figure class="package-icon">
										<img src="@/assets/images/package-icon.svg" alt="" />
									</figure>
									<h2 class="type-title">{{ pack.name }}</h2>
									<ul class="type-specification">
										<li
											v-for="specification in pack.specifications"
											:key="specification.id"
										>
											<div class="dot"></div>
											<span> {{ specification.value }}</span>
										</li>
									</ul>
									<div class="type-price">
										<span>{{ $t("labels.startFrom") }}</span>
										<p>{{ pack.price }}</p>
									</div>
									<div class="get-service">
										<button @click="show()">{{ $t("labels.getIt") }}</button>
									</div>
								</div>
							</div>
						</div>
						<div
							class="package-types-5"
							v-if="selectedSection.types.length === 5"
						>
							<div
								v-for="pack in selectedSection.types"
								:key="pack.id"
								class="package-type-container"
								:class="{
									'type-main': pack.is_main
								}"
							>
								<div class="package-type">
									<figure
										class="package-icon"
										:class="{ hidden: !pack.is_main }"
									>
										<img src="@/assets/images/package-icon.svg" alt="" />
									</figure>
									<h2 class="type-title">{{ pack.name }}</h2>
									<ul class="type-specification">
										<li
											v-for="specification in pack.specifications"
											:key="specification.id"
										>
											<div class="dot"></div>
											<span> {{ specification.value }}</span>
										</li>
									</ul>
									<div class="type-price">
										<span>{{ $t("labels.startFrom") }}</span>
										<p>{{ pack.price }}</p>
									</div>
									<div class="get-service">
										<button @click="show()">{{ $t("labels.getIt") }}</button>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="selectedSection.types.length === 0"
							class="mt-32 text-center"
						>
							<h2 class="title text-2xl sm:text-4xl xl:text-6xls">
								coming soon
							</h2>
						</div>
					</div>
				</div>
			</section>
			<!-- <section v-if="service.stages.length">
				<our-work-progress :stages="service.stages" />
			</section>
			<section class="flex">
				<button
					v-if="!service.sections.length"
					class="btn is-main mx-auto button"
					@click="show()"
				>
					{{ $t("labels.applyForService") }}
				</button>
				<template>
					<modal
						name="my-first-modal"
						:scrollable="true"
						:height="'auto'"
						class="py-4"
						:width="500"
					>
						<forms
							:UploadFile="false"
							Upload="Upload file"
							message="Description (Optional) "
							title="Order Now "
							:Companyname="false"
							Company="Company name "
						/>
					</modal>
				</template>
			</section> -->
		</div>
	</div>
</template>
<script>
import Tecnologies from "../components/Tecnologies.vue";
import Hero from "../components/Hero.vue";

export default {
	components: {
		Tecnologies,
		Hero
	},

	data() {
		return {
			service: {
				title: "Hosting",
				discription:
					"We give you the best options at the lowest prices. We offer various solutions to suit your needs.",
				main_media: "images/Hosting.svg",
				tecnologies: [
					{ id: 3, image: "images/Microsoft.png", name: "Microsoft" },
					{ id: 4, image: "images/Linux.png", name: "Linux" },
					{ id: 5, image: "images/Windows server.png", name: "Windows server" },
					{ id: 6, image: "images/Centos 7.png", name: "Centos 7" },
					{ id: 7, image: "images/Ubuntu.jpg", name: "Ubuntu" },
					{ id: 8, image: "images/Whm.png", name: "Whm" },
					{ id: 9, image: "images/Cpanel.png", name: "Cpanel" },
					{
						id: 10,
						image: "images/Centos web panel.jpg",
						name: "Centos web panel"
					},
					{ id: 11, image: "images/Sql server.png", name: "Sql server" },
					{ id: 12, image: "images/Aws.jpg", name: "Aws" },
					{ id: 13, image: "images/Google Cloud.png", name: "Google Cloud" },
					{
						id: 14,
						image: "images/Microsoft Azure.png",
						name: "Microsoft Azure"
					},
					{
						id: 15,
						image: "images/Apache",
						name: "Apache"
					},
					{ id: 16, image: "images/Nginx.png", name: "Nginx" },
					{
						id: 17,
						image: "images/Hosting.svg",
						name: "Varit server Managing System"
					}
				],
				sections: [
					{
						name: "Vps",
						id: 1,
						discription:
							"With our datacenters in Germany and USA, we offer you the best quality, higher resources, and cheapest VPSs.",
						types: [
							{
								id: 1,
								name: "WVPS 200",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Storage 200 GB (SSD)"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "CPUs 4"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "RAM 8"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Speed 200 mbit/s"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "OS Linux"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									}
								],
								price: "50 $/M"
							},
							{
								id: 2,
								name: "WVPS 400",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Storage 400 GB (SSD)"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "CPUs 6"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "RAM 16"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Speed 400 mbit/s"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "OS Linux"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									}
								],
								price: "80 $/M"
							},
							{
								id: 3,
								name: "WVPS 800",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Storage 800 GB (SSD)"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "CPUs 8"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "RAM 30"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Speed 600 mbit/s"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "OS Linux"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									}
								],
								price: "120 $/M"
							},
							{
								id: 4,
								name: "WVPS 1600",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Storage 1600 GB (SSD)"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "CPUs 10"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "RAM 60"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Speed 1000 mbit/s"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "OS Linux"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									}
								],
								price: "170 $/M"
							}
						]
					},
					{
						name: "Shared hosting",
						id: 2,
						discription:
							"The best choice for small businesses. We gave you a shared hosting with a VPS performance.",
						types: [
							{
								id: 1,
								name: "Landing",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Space 1 G ssd"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "Bandwidth 40 G"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Backups 6/week"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Free SSL Yes"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Support 24/7"
									},
									{
										id: 6,
										key: "lorem-lorem-lorem",
										value: "Dedicated IP No"
									},
									{
										id: 7,
										key: "lorem-lorem-lorem",
										value: "Processors 8"
									},
									{
										id: 8,
										key: "lorem-lorem-lorem",
										value: "Speed 600 m/s"
									},
									{
										id: 9,
										key: "lorem-lorem-lorem",
										value: "Data center Germany"
									}
								],
								price: "35 $/Y"
							},
							{
								id: 2,
								name: "Wordpress",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Space 3 G ssd"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "Bandwidth 120 G"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Backups 6/week"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Free SSL Yes"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Support 24/7"
									},
									{
										id: 6,
										key: "lorem-lorem-lorem",
										value: "Dedicated IP No"
									},
									{
										id: 7,
										key: "lorem-lorem-lorem",
										value: "Processors 8"
									},
									{
										id: 8,
										key: "lorem-lorem-lorem",
										value: "Speed 600 m/s"
									},
									{
										id: 9,
										key: "lorem-lorem-lorem",
										value: "Data center Germany"
									}
								],
								price: "80 $/Y"
							},
							{
								id: 3,
								name: "Company",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Space 10 G ssd"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "Bandwidth 400 G"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Backups 6/week"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Free SSL Yes"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Support 24/7"
									},
									{
										id: 6,
										key: "lorem-lorem-lorem",
										value: "Dedicated IP No"
									},
									{
										id: 7,
										key: "lorem-lorem-lorem",
										value: "Processors 8"
									},
									{
										id: 8,
										key: "lorem-lorem-lorem",
										value: "Speed 600 m/s"
									},
									{
										id: 9,
										key: "lorem-lorem-lorem",
										value: "Data center Germany"
									}
								],
								price: "160 $/Y"
							},
							{
								id: 4,
								name: "Bigger Company",
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Space 20 G ssd"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "Bandwidth 800 G"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Backups 6/week"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Free SSL Yes"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Support 24/7"
									},
									{
										id: 6,
										key: "lorem-lorem-lorem",
										value: "Dedicated IP No"
									},
									{
										id: 7,
										key: "lorem-lorem-lorem",
										value: "Processors 8"
									},
									{
										id: 8,
										key: "lorem-lorem-lorem",
										value: "Speed 600 m/s"
									},
									{
										id: 9,
										key: "lorem-lorem-lorem",
										value: "Data center Germany"
									}
								],
								price: "300 $/Y"
							},
							{
								id: 5,
								name: "Platform",
								is_main: true,
								specifications: [
									{
										id: 1,
										key: "lorem-lorem-lorem",
										value: "Space 50 G ssd"
									},
									{
										id: 2,
										key: "lorem-lorem-lorem",
										value: "Bandwidth 2 T"
									},
									{
										id: 3,
										key: "lorem-lorem-lorem",
										value: "Cpanel Yes"
									},
									{
										id: 4,
										key: "lorem-lorem-lorem",
										value: "Backups 6/week"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Free SSL Yes"
									},
									{
										id: 5,
										key: "lorem-lorem-lorem",
										value: "Support 24/7"
									},
									{
										id: 6,
										key: "lorem-lorem-lorem",
										value: "Dedicated IP No"
									},
									{
										id: 7,
										key: "lorem-lorem-lorem",
										value: "Processors 8"
									},
									{
										id: 8,
										key: "lorem-lorem-lorem",
										value: "Speed 1000 m/s"
									},
									{
										id: 9,
										key: "lorem-lorem-lorem",
										value: "Data center Germany"
									}
								],
								price: "470 $/Y"
							}
						]
					},
					{
						name: "Dedicated servers",
						id: 3,
						discription:
							"Very soon you will be able to choose whatever resources you need from our Dedicated servers options.",
						types: [
							// {
							// 	id: 1,
							// 	name: "pack1 3",
							// 	specifications: [
							// 		{
							// 			id: 1,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 2,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 3,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 4,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 5,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		}
							// 	],
							// 	price: "2500 $/Y"
							// },
							// {
							// 	id: 2,
							// 	name: "pack2 3",
							// 	specifications: [
							// 		{
							// 			id: 1,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 2,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 3,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 4,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 5,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		}
							// 	],
							// 	price: "2500 $/Y"
							// },
							// {
							// 	id: 3,
							// 	name: "pack3 3",
							// 	specifications: [
							// 		{
							// 			id: 1,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 2,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 3,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 4,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		},
							// 		{
							// 			id: 5,
							// 			key: "lorem-lorem-lorem",
							// 			value: "lorem lorem lorem"
							// 		}
							// 	],
							// 	price: "2500 $/Y"
							// }
						]
					}
				]
			},
			selectedSection: null,
			chunk: [],
			chunks: []
		};
	},
	methods: {
		changeSection(section) {
			this.selectedSection = section;
			const stories = document.querySelectorAll("li.section-details");
			for (let index = 0; index < stories.length; index++) {
				stories[index].classList.remove("selected");
			}
			document
				.getElementById(`section-${section.id}`)
				.classList.toggle("selected");
		}
	},
	mounted() {
		this.selectedSection = this.service.sections[0];
		document
			.getElementById(`section-${this.selectedSection.id}`)
			.classList.add("selected");
	}
};
</script>
